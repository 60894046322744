import { ApolloError } from '@apollo/client'
import { Chain, LiquidityLock, useLiquidityLocksByTokenQuery } from 'graphql/data/__generated__/types-and-hooks'
import { PollingInterval, usePollQueryWhileMounted } from 'graphql/data/util'
import gql from 'graphql-tag'
import { useMemo } from 'react'

gql`
  query LiquidityLocksByToken($chain: Chain!, $address: String!) {
    liquidityLocksByToken(chain: $chain, address: $address) {
      lockId
      pair
      owner
      created
      duration
      token0
      token1
      percentSupply
      amountToken1
      amountToken0
      active
      version
    }
  }
`

interface LiquidityLocksReturnValue {
  liquidityLocks?: LiquidityLock[]
  loading: boolean
  error?: ApolloError
}

export function useLiquidityLocksByToken(chain: Chain, address: string): LiquidityLocksReturnValue {
  const { data, loading, error } = usePollQueryWhileMounted(
    useLiquidityLocksByTokenQuery({
      variables: { chain, address },
      skip: address.length === 0,
    }),
    PollingInterval.Slow
  )

  return useMemo(
    () => ({
      liquidityLocks: data?.liquidityLocksByToken as LiquidityLock[],
      loading,
      error,
    }),
    [data, error, loading]
  )
}
