import { BaseVariant, FeatureFlag, useBaseFlag } from '../index'

export function useFotAdjustmentsFlag(): BaseVariant {
  return useBaseFlag(FeatureFlag.fotAdjustedmentsEnabled)
}

// eslint-disable-next-line import/no-unused-modules
export function useFotAdjustmentsEnabled(): boolean {
  return useFotAdjustmentsFlag() === BaseVariant.Control
}
