// a list of tokens by chain
import { ChainId, Currency, Token } from '@electroswap/sdk-core'

import { BOLT, nativeOnChain, USDC_ELECTRONEUM, USDT_ELECTRONEUM, WRAPPED_NATIVE_CURRENCY } from './tokens'

type ChainTokenList = {
  readonly [chainId: number]: Token[]
}

type ChainCurrencyList = {
  readonly [chainId: number]: Currency[]
}

const WRAPPED_NATIVE_CURRENCIES_ONLY: ChainTokenList = Object.fromEntries(
  Object.entries(WRAPPED_NATIVE_CURRENCY)
    .map(([key, value]) => [key, [value]])
    .filter(Boolean)
)

/**
 * Shows up in the currency select for swap and add liquidity
 */
export const COMMON_BASES: ChainCurrencyList = {
  [ChainId.ELECTRONEUM]: [
    nativeOnChain(ChainId.ELECTRONEUM),
    //WRAPPED_NATIVE_CURRENCY[ChainId.ELECTRONEUM] as Token,
    USDC_ELECTRONEUM,
    USDT_ELECTRONEUM,
    BOLT[ChainId.ELECTRONEUM],
  ],
  [ChainId.ELECTRONEUM_TEST]: [
    nativeOnChain(ChainId.ELECTRONEUM_TEST),
    //WRAPPED_NATIVE_CURRENCY[ChainId.ELECTRONEUM_TEST] as Token,
    BOLT[ChainId.ELECTRONEUM_TEST],
  ],
}

// used to construct the list of all pairs we consider by default in the frontend
export const BASES_TO_TRACK_LIQUIDITY_FOR: ChainTokenList = {
  ...WRAPPED_NATIVE_CURRENCIES_ONLY,
  [ChainId.ELECTRONEUM]: [...WRAPPED_NATIVE_CURRENCIES_ONLY[ChainId.ELECTRONEUM]],
  [ChainId.ELECTRONEUM_TEST]: [...WRAPPED_NATIVE_CURRENCIES_ONLY[ChainId.ELECTRONEUM_TEST]],
}
export const PINNED_PAIRS: { readonly [chainId: number]: [Token, Token][] } = {
  [ChainId.ELECTRONEUM]: [
    // [USDC_MAINNET, USDT],
    [WRAPPED_NATIVE_CURRENCY[ChainId.ELECTRONEUM] as Token, BOLT[ChainId.ELECTRONEUM]],
  ],
}
