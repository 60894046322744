import { InterfaceElementName } from '@uniswap/analytics-events'
import { /*DollarSign,*/ Terminal } from 'react-feather'
import styled from 'styled-components'
import { lightTheme } from 'theme/colors'

import darkArrowImgSrc from './images/aboutArrowDark.png'
import lightArrowImgSrc from './images/aboutArrowLight.png'
//import darkDollarImgSrc from './images/aboutDollarDark.png'
import darkTerminalImgSrc from './images/aboutTerminalDark.png'
//import nftCardImgSrc from './images/nftCard.png'
import swapCardImgSrc from './images/swapCard.png'

export const MAIN_CARDS = [
  {
    to: '/swap',
    title: 'Swap tokens',
    description: 'Buy, sell, and explore tokens on Electroneum',
    cta: 'Trade Tokens',
    darkBackgroundImgSrc: swapCardImgSrc,
    lightBackgroundImgSrc: swapCardImgSrc,
    elementName: InterfaceElementName.ABOUT_PAGE_SWAP_CARD,
  },
  //   {
  //     to: '/nfts',
  //     title: 'Trade NFTs',
  //     description: 'Buy and sell NFTs across marketplaces to find more listings at better prices.',
  //     cta: 'Explore NFTs',
  //     darkBackgroundImgSrc: nftCardImgSrc,
  //     lightBackgroundImgSrc: nftCardImgSrc,
  //     elementName: InterfaceElementName.ABOUT_PAGE_NFTS_CARD,
  //   },
]

const StyledCardLogo = styled.img`
  min-width: 20px;
  min-height: 20px;
  max-height: 48px;
  max-width: 48px;
`

export const MORE_CARDS = [
  //   {
  //     to: 'https://support.electroswap.io/hc/en-us/articles/11306574799117',
  //     external: true,
  //     title: 'Buy crypto',
  //     description: 'Buy crypto with your credit card or bank account at the best rates.',
  //     lightIcon: <DollarSign color={lightTheme.neutral3} size={48} />,
  //     darkIcon: <StyledCardLogo src={darkDollarImgSrc} alt="Earn" />,
  //     cta: 'Buy now',
  //     elementName: InterfaceElementName.ABOUT_PAGE_BUY_CRYPTO_CARD,
  //   },
  {
    to: '/pools',
    title: 'Earn',
    description: 'Provide liquidity to pools on ElectroSwap and earn fees on swaps.',
    lightIcon: <StyledCardLogo src={lightArrowImgSrc} alt="Analytics" />,
    darkIcon: <StyledCardLogo src={darkArrowImgSrc} alt="Analytics" />,
    cta: 'Provide liquidity',
    elementName: InterfaceElementName.ABOUT_PAGE_EARN_CARD,
  },
  {
    to: 'https://electroswap.io/docs/category/for-developers',
    external: true,
    title: 'Developer docs',
    description: 'Build tokens, apps and tools on the largest DeFi protocol on Electroneum.',
    lightIcon: <Terminal color={lightTheme.neutral3} size={48} />,
    darkIcon: <StyledCardLogo src={darkTerminalImgSrc} alt="Developers" />,
    cta: 'Developer docs',
    elementName: InterfaceElementName.ABOUT_PAGE_DEV_DOCS_CARD,
  },
]
