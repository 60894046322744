import { useState } from 'react'

export default function ConnectButton({ full }: { full: boolean }) {
  const [isFull] = useState(full)
  return (
    <button
      className="poppins-medium"
      style={{
        backgroundColor: '#10345c',
        width: `${isFull ? '100%' : null}`,
        padding: '0.85rem 2rem',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'white',
        borderRadius: '15px',
        border: 'none',
        cursor: 'pointer',
        transition: 'background-color 0.3s ease',
        textDecoration: 'none',
        textAlign: 'center',
        display: 'inline-block',
        outline: 'none',
        boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
        whiteSpace: 'nowrap',
      }}
      onMouseEnter={(event: any) => {
        event.target.style.backgroundColor = '#0857EF'
      }}
      onMouseLeave={(event: any) => {
        event.target.style.backgroundColor = '#10345c'
      }}
    >
      Connect Wallet
    </button>
  )
}
