import { ChainId, Currency, CurrencyAmount, Price, Token } from '@electroswap/sdk-core'
import { useWeb3React } from '@web3-react/core'
import { USDC_ELECTRONEUM, USDC_ELECTRONEUM_TEST } from 'constants/tokens'
import { Chain, HistoryDuration, useTokenPriceQuery } from 'graphql/data/__generated__/types-and-hooks'
import tryParseCurrencyAmount from 'lib/utils/tryParseCurrencyAmount'
import { useMemo, useRef } from 'react'

// Stablecoin amounts used when calculating spot price for a given currency.
// The amount is large enough to filter low liquidity pairs.
const STABLECOIN_AMOUNT_OUT: { [chainId: number]: CurrencyAmount<Token> } = {
  [ChainId.ELECTRONEUM]: CurrencyAmount.fromRawAmount(USDC_ELECTRONEUM, 1_000e6),
  [ChainId.ELECTRONEUM_TEST]: CurrencyAmount.fromRawAmount(USDC_ELECTRONEUM_TEST, 1_000e6),
}
/**
 * Returns the price in USDC of the input currency
 * @param currency currency to compute the USDC price of
 */
export default function useStablecoinPrice(currency?: Currency): Price<Currency, Token> | undefined {
  const chainId = currency?.chainId
  const amountOut = chainId ? STABLECOIN_AMOUNT_OUT[chainId] : undefined
  const stablecoin = amountOut?.currency

  const { data } = useTokenPriceQuery({
    variables: { chain: Chain.Electroneum, address: currency?.wrapped.address, duration: HistoryDuration.Day },
    skip: false,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-first',
  })

  const price = useMemo(() => {
    if (currency && stablecoin && data?.token?.market?.price?.value) {
      return new Price(
        currency,
        stablecoin,
        10 ** currency.decimals,
        (data?.token?.market?.price?.value * 10 ** stablecoin.decimals).toFixed(0)
      )
    }
    return undefined
  }, [data, currency, stablecoin])

  // const { trade } = useRoutingAPITrade(
  //   false /* skip */,
  //   TradeType.EXACT_OUTPUT,
  //   amountOut,
  //   currency,
  //   INTERNAL_ROUTER_PREFERENCE_PRICE
  // )
  // const price = useMemo(() => {
  //   if (!currency || !stablecoin) {
  //     console.log('first return')
  //     return undefined
  //   }

  //   // handle usdc
  //   if (currency?.wrapped.equals(stablecoin)) {
  //     console.log('second return')
  //     return new Price(stablecoin, stablecoin, '1', '1')
  //   }

  //   // if initial quoting fails, we may end up with a DutchOrderTrade
  //   if (trade && trade instanceof ClassicTrade) {
  //     const { numerator, denominator } = trade.routes[0].midPrice
  //     const price = new Price(currency, stablecoin, denominator, numerator)
  //     //console.log('price', price.quote(1))
  //     return price
  //   }

  //   return undefined
  // }, [currency, stablecoin, trade])

  const lastPrice = useRef(price)
  if (
    !price ||
    !lastPrice.current ||
    !price.equalTo(lastPrice.current) ||
    !price.baseCurrency.equals(lastPrice.current.baseCurrency)
  ) {
    lastPrice.current = price
  }
  return lastPrice.current
}

export function useStablecoinValue(currencyAmount: CurrencyAmount<Currency> | undefined | null) {
  const price = useStablecoinPrice(currencyAmount?.currency)

  return useMemo(() => {
    if (!price || !currencyAmount) return null
    try {
      return price.quote(currencyAmount)
    } catch (error) {
      return null
    }
  }, [currencyAmount, price])
}

/**
 *
 * @param fiatValue string representation of a USD amount
 * @returns CurrencyAmount where currency is stablecoin on active chain
 */
export function useStablecoinAmountFromFiatValue(fiatValue: number | null | undefined) {
  const { chainId } = useWeb3React()
  const stablecoin = chainId ? STABLECOIN_AMOUNT_OUT[chainId]?.currency : undefined

  return useMemo(() => {
    if (fiatValue === null || fiatValue === undefined || !chainId || !stablecoin) {
      return undefined
    }

    // trim for decimal precision when parsing
    const parsedForDecimals = fiatValue.toFixed(stablecoin.decimals).toString()
    try {
      // parse USD string into CurrencyAmount based on stablecoin decimals
      return tryParseCurrencyAmount(parsedForDecimals, stablecoin)
    } catch (error) {
      return undefined
    }
  }, [chainId, fiatValue, stablecoin])
}
