import { IRoute, RouteV2, RouteV3 } from '@electroswap/router-sdk'
import { Currency, Token } from '@electroswap/sdk-core'
import { Pair } from '@electroswap/v2-sdk'
import { Pool } from '@electroswap/v3-sdk'
import { BigNumber } from '@ethersproject/bignumber'
import { id as ethersId } from '@ethersproject/hash'
import { useWeb3React } from '@web3-react/core'
import { Chain } from 'graphql/data/__generated__/types-and-hooks'
import { useLiquidityLocksByToken } from 'graphql/data/LiquidityLocks'
import { useSingleCallResult, useSingleContractMultipleData, useSingleContractWithCallData } from 'lib/hooks/multicall'
import { useMemo } from 'react'

import { useLiquidityLockerV2Contract, useLiquidityLockerV3Contract, usePairContract } from './useContract'

const PAIR_TOTAL_SUPPLY = ethersId('totalSupply()')
const PAIR_RESERVES = ethersId('getReserves()')
const PAIR_TOKEN_0 = ethersId('token0()')
const PAIR_TOKEN_1 = ethersId('token1()')

export interface LockedLiquidity {
  locksLoading: boolean
  isLocked?: boolean
  percentLocked?: string
  lockInfos?: LockInfo[]
}

interface LockInfo {
  lockId: number
  pair: string
  created: number
  duration: number
  owner: string
  token0: string
  token1: string
  percentSupply: number
  amountToken0: number
  amountToken1: number
  version: string
  active: boolean
}

export function useCheckForLiquidityLocks(
  inputCurrency: Currency | undefined,
  outputCurrency: Currency | undefined,
  routes: IRoute<Currency, Currency, Pair | Pool>[] | undefined
): LockedLiquidity {
  const { chainId } = useWeb3React()

  const v2PairAddress = useMemo(() => {
    const v2Routes = routes?.filter((r) => r instanceof RouteV2 && r?.pairs.length > 0) as RouteV2<Currency, Currency>[]
    return v2Routes && v2Routes[0]?.pairs[0].liquidityToken.address
  }, [routes])

  const v3PoolAddresses = useMemo(() => {
    const v3Routes = routes?.filter((r) => r instanceof RouteV3 && r?.pools.length > 0) as RouteV3<Currency, Currency>[]
    return (
      (v3Routes &&
        v3Routes.map((v3Route) =>
          Pool.getAddress(v3Route?.pools?.[0]?.token0, v3Route?.pools?.[0]?.token1, v3Route?.pools?.[0]?.fee)
        )) ||
      []
    )
  }, [routes])

  const primaryToken = useMemo(() => {
    const notPrimary = ['WETN', 'eUSDC', 'eUSDT']
    const input =
      inputCurrency?.isToken && !notPrimary.includes(inputCurrency?.symbol || 'WETN')
        ? (inputCurrency as Token).address
        : undefined
    const output =
      outputCurrency?.isToken && !notPrimary.includes(outputCurrency?.symbol || 'WETN')
        ? (outputCurrency as Token).address
        : undefined

    if (input !== undefined && output !== undefined) {
      return output
    } else if (input !== undefined) {
      return input
    } else if (output !== undefined) {
      return output
    }
    return ''
  }, [inputCurrency, outputCurrency])

  const {
    liquidityLocks: gqlLocks,
    loading: gqlLoading,
    error: gqlError,
  } = useLiquidityLocksByToken(chainId === 52014 ? Chain.Electroneum : Chain.ElectroneumTest, primaryToken)

  const skipOnChain = gqlError === undefined || gqlLoading

  const onChainLockData = useOnChainData(skipOnChain, v2PairAddress, v3PoolAddresses)

  return {
    isLocked: skipOnChain ? gqlLocks?.some((lock) => lock.active) : onChainLockData.isLocked,
    percentLocked: skipOnChain
      ? '' +
        gqlLocks
          ?.filter((lock) => lock.active)
          ?.map((lock) => lock.percentSupply)
          ?.reduce((l1, l2) => (l1 ? l1 : 0) + (l2 ? l2 : 0), 0)
          ?.toFixed(2)
      : onChainLockData.percentLocked,
    lockInfos: skipOnChain ? (gqlLocks as unknown as LockInfo[]) : onChainLockData.lockInfos,
    locksLoading: skipOnChain ? gqlLoading : onChainLockData.locksLoading,
  }
}

const useOnChainData = (skip: boolean, v2PairAddress: string | undefined, v3PoolAddresses: string[]) => {
  //console.log('usingOnChainLockData', !skip)

  // V2 - OnChain
  const liquidityLockerV2 = useLiquidityLockerV2Contract()
  const pairContract = usePairContract(skip ? undefined : v2PairAddress)
  const pairData = useSingleContractWithCallData(
    skip ? undefined : pairContract,
    skip ? [] : [PAIR_TOTAL_SUPPLY, PAIR_RESERVES, PAIR_TOKEN_0, PAIR_TOKEN_1]
  )
  const lockIdsByPairV2Call = useSingleCallResult(
    skip ? undefined : liquidityLockerV2,
    'getLockIDsByPairAddress',
    skip ? undefined : [v2PairAddress]
  )

  const totalSupply = pairData?.[0]?.result?.[0]
  const reserves = pairData?.[1]?.result
  const token0 = pairData?.[2]?.result?.[0]
  const token1 = pairData?.[3]?.result?.[0]
  const lockIdsV2 = lockIdsByPairV2Call?.result?.[0]

  const pairAmountToken0 = reserves?.reserve0
  const pairAmountToken1 = reserves?.reserve1

  const arrayOfLockIdsV2 =
    lockIdsV2?.map((lockId: number) => {
      return [lockId]
    }) || []
  const lockInfoResultV2Call = useSingleContractMultipleData(
    skip ? undefined : liquidityLockerV2,
    'getLockById',
    skip ? [] : arrayOfLockIdsV2
  )
  const { amountLockedV2, lockInfosV2, isLockedV2, lockInfoV2Loading } = useMemo(() => {
    const now = BigNumber.from(parseInt('' + Date.now() / 1000))
    let amountLockedV2 = BigNumber.from(0)
    const lockInfosV2 =
      lockInfoResultV2Call &&
      lockInfoResultV2Call?.map((lockInfo) => {
        const rawLock = lockInfo.result?.[0]

        const isActive = rawLock && rawLock?.created.add(rawLock?.duration).gt(now) && !rawLock.amount.isZero()
        amountLockedV2 = isActive ? amountLockedV2.add(rawLock.amount) : amountLockedV2

        const percentSupply = rawLock?.amount.mul(10000).div(totalSupply).toNumber() / 100
        return {
          lockId: rawLock?.lockID,
          pair: rawLock?.pair,
          created: rawLock?.created,
          duration: rawLock?.duration,
          owner: rawLock?.owner,
          token0,
          token1,
          percentSupply,
          amountToken0: (percentSupply * pairAmountToken0) / 100,
          amountToken1: (percentSupply * pairAmountToken1) / 100,
          version: '2.1',
          active: isActive,
        }
      })

    return {
      amountLockedV2,
      lockInfosV2,
      isLockedV2: amountLockedV2 ? amountLockedV2.gt(BigNumber.from(0)) : undefined,
      lockInfoV2Loading: lockInfoResultV2Call?.some((lockInfo) => lockInfo.loading),
    }
  }, [lockInfoResultV2Call, token0, token1, pairAmountToken0, pairAmountToken1, totalSupply])

  /* V3 */
  const liquidityLockerV3 = useLiquidityLockerV3Contract()

  const lockIdsByV3PoolCall = useSingleContractMultipleData(
    skip ? undefined : liquidityLockerV3,
    'getLockIdsByPool',
    skip || v3PoolAddresses.length === 0
      ? []
      : v3PoolAddresses.map((v3Address) => {
          return [v3Address]
        })
  )

  const arrayOfLockIdsV3 = lockIdsByV3PoolCall?.map((resp) => [resp?.result?.[0]?.[0]]) || []

  const lockInfoResultV3Call = useSingleContractMultipleData(
    skip ? undefined : liquidityLockerV3,
    'getLockById',
    skip ? [] : arrayOfLockIdsV3
  )

  const { lockInfosV3, isLockedV3, lockInfoV3Loading } = useMemo(() => {
    let amount0LockedV3 = BigNumber.from(0)
    let amount1LockedV3 = BigNumber.from(0)

    const lockInfosV3 =
      lockInfoResultV3Call &&
      lockInfoResultV3Call?.map((lockInfo) => {
        const rawLock = lockInfo.result?.[0]

        const isActive =
          rawLock && parseInt(rawLock?.created) + parseInt(rawLock?.duration) > Math.floor(Date.now() / 1000)
        amount0LockedV3 =
          isActive && rawLock?.lockedAmount0 !== undefined
            ? amount0LockedV3.add(rawLock?.lockedAmount0)
            : amount0LockedV3
        amount1LockedV3 =
          isActive && rawLock?.lockedAmount1 !== undefined
            ? amount1LockedV3.add(rawLock?.lockedAmount1)
            : amount1LockedV3

        return {
          lockId: rawLock?.tokenId,
          pair: rawLock?.pool,
          created: rawLock?.created,
          duration: rawLock?.duration,
          owner: rawLock?.owner,
          token0: rawLock?.token0,
          token1: rawLock?.token1,
          percentSupply:
            ((rawLock?.lockedAmount0 / rawLock?.poolAmount0) * 100 +
              (rawLock?.lockedAmount1 / rawLock?.poolAmount1) * 100) /
            2,
          amountToken0: rawLock?.lockedAmount0,
          amountToken1: rawLock?.lockedAmount1,
          version: '3.0',
          active: isActive,
        }
      })

    return {
      lockInfosV3,
      //amount0LockedV3,
      //amount1LockedV3,
      isLockedV3:
        amount0LockedV3 && amount1LockedV3
          ? amount0LockedV3.gt(BigNumber.from(0)) || amount1LockedV3.gt(BigNumber.from(0))
          : undefined,
      lockInfoV3Loading: lockInfoResultV3Call?.some((lockInfo) => lockInfo.loading),
    }
  }, [lockInfoResultV3Call])

  const percentLocked = useMemo(() => {
    return amountLockedV2 && totalSupply
      ? (amountLockedV2.mul(10000).div(totalSupply).toNumber() / 100).toFixed(2)
      : lockInfosV3
      ? lockInfosV3
          ?.map((lock) => lock.percentSupply)
          ?.reduce((l1, l2) => l1 + l2, 0)
          ?.toFixed(2)
      : undefined
  }, [amountLockedV2, totalSupply, lockInfosV3])

  return {
    isLocked: isLockedV2 || isLockedV3,
    percentLocked,
    lockInfos:
      lockInfosV3 && lockInfosV2
        ? lockInfosV3.concat(lockInfosV2).sort((a, b) => {
            return a.percentSupply < b.percentSupply ? 1 : -1
          })
        : [],
    locksLoading: lockInfoV2Loading || lockInfoV3Loading,
  }
}
