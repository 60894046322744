import { createReducer } from '@reduxjs/toolkit'

import { selectPercent, selectDuration } from './actions'

// eslint-disable-next-line import/no-unused-modules
export interface LockV3State {
  readonly percent: number
  readonly duration: number
}

const initialState: LockV3State = {
  percent: 100,
  duration: 15552000,
}

export default createReducer<LockV3State>(initialState, (builder) =>
  builder.addCase(selectPercent, (state, { payload: { percent, } }) => {
    return {
      ...state,
      percent,
    }
  })
  .addCase(selectDuration, (state, { payload: { duration, } }) => {
    return {
      ...state,
      duration,
    }
  })
)
