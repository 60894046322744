import { ChainId } from '@electroswap/sdk-core'
import useHttpLocations from 'hooks/useHttpLocations'
import { useMemo } from 'react'
import { isAddress } from 'utils'

import ElectroneumLogo from '../../assets/images/electroneum-logo.png'
import { NATIVE_CHAIN_ID } from '../../constants/tokens'

type Network = 'electroneum' | 'electroneum_test'

// eslint-disable-next-line import/no-unused-modules
export function chainIdToNetworkName(networkId: ChainId): Network {
  switch (networkId) {
    case ChainId.ELECTRONEUM:
      return 'electroneum'
    case ChainId.ELECTRONEUM_TEST:
      return 'electroneum_test'
    default:
      return 'electroneum'
  }
}

export function getNativeLogoURI(chainId: ChainId = ChainId.ELECTRONEUM): string {
  switch (chainId) {
    default:
      return ElectroneumLogo
  }
}

function getTokenLogoURI(address: string, chainId: ChainId = ChainId.ELECTRONEUM): string | void {
  return `https://app.electroswap.io/images/${address}.png`
}

export default function useCurrencyLogoURIs(
  currency:
    | {
        isNative?: boolean
        isToken?: boolean
        address?: string
        chainId: number
        logoURI?: string | null
      }
    | null
    | undefined
): string[] {
  const locations = useHttpLocations(currency?.logoURI)
  return useMemo(() => {
    const logoURIs = [...locations]
    if (currency) {
      if (currency.isNative || currency.address === NATIVE_CHAIN_ID) {
        logoURIs.push(getNativeLogoURI(currency.chainId))
      } else if (currency.isToken || currency.address) {
        const checksummedAddress = isAddress(currency.address)
        const logoURI = checksummedAddress && getTokenLogoURI(checksummedAddress, currency.chainId)
        if (logoURI) {
          logoURIs.push(logoURI)
        }
      }
    }
    return logoURIs
  }, [currency, locations])
}
