import { useEffect, useState } from 'react'
import styled from 'styled-components'

import { ReactComponent as EsLogoSvg } from '../../assets/svg/es_logo.svg'
import { ReactComponent as FullLogo } from '../../assets/svg/logo.svg'

// ESLint reports `fill` is missing, whereas it exists on an SVGProps type
type SVGProps = React.SVGProps<SVGSVGElement> & {
  fill?: string
  height?: string | number
  width?: string | number
  gradientId?: string
}
function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window
  return {
    width,
    height,
  }
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions())

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions())
    }
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return windowDimensions
}
export const EsIcon = (props: SVGProps) => {
  const { width } = useWindowDimensions()
  return (
    <Container>
      {width < 550 ? (
        <EsLogoSvg
          {...props}
          width={100}
          style={{ marginLeft: '-10px', filter: 'drop-shadow(3px 3px 2px rgb(1 9 21 / 0.7))' }}
        />
      ) : (
        <FullLogo {...props} width={300} style={{ filter: 'drop-shadow(3px 3px 2px rgb(1 9 21 / 0.7))' }} />
      )}
    </Container>
  )
}

const Container = styled.div`
  position: relative;
`
