import { Percent, Token, V2_FACTORY_ADDRESSES } from '@electroswap/sdk-core'
import { computePairAddress, Pair } from '@electroswap/v2-sdk'
import { useWeb3React } from '@web3-react/core'
import { SupportedLocale } from 'constants/locales'
import { L2_DEADLINE_FROM_NOW } from 'constants/misc'
import { PAIR_INTERFACE, TOKEN_INTERFACE, useLiquidityLockerV2Contract } from 'hooks/useContract'
import JSBI from 'jsbi'
import {
  NEVER_RELOAD,
  useMultipleContractSingleData,
  useSingleCallResult,
  useSingleContractMultipleData,
} from 'lib/hooks/multicall'
import { useCallback, useMemo } from 'react'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import { RouterPreference } from 'state/routing/types'
import { UserAddedToken } from 'types/tokens'

import { BASES_TO_TRACK_LIQUIDITY_FOR, PINNED_PAIRS } from '../../constants/routing'
import { useDefaultActiveTokens } from '../../hooks/Tokens'
import {
  addSerializedPair,
  addSerializedToken,
  updateHideAndroidAnnouncementBanner,
  updateHideClosedPositions,
  updateUserDeadline,
  updateUserLocale,
  updateUserRouterPreference,
  updateUserSlippageTolerance,
} from './reducer'
import { SerializedPair, SerializedToken, SlippageTolerance } from './types'

export function serializeToken(token: Token): SerializedToken {
  return {
    chainId: token.chainId,
    address: token.address,
    decimals: token.decimals,
    symbol: token.symbol,
    name: token.name,
  }
}

export function deserializeToken(serializedToken: SerializedToken, Class: typeof Token = Token): Token {
  return new Class(
    serializedToken.chainId,
    serializedToken.address,
    serializedToken.decimals,
    serializedToken.symbol,
    serializedToken.name
  )
}

export function useUserLocale(): SupportedLocale | null {
  return useAppSelector((state) => state.user.userLocale)
}

export function useUserLocaleManager(): [SupportedLocale | null, (newLocale: SupportedLocale) => void] {
  const dispatch = useAppDispatch()
  const locale = useUserLocale()

  const setLocale = useCallback(
    (newLocale: SupportedLocale) => {
      dispatch(updateUserLocale({ userLocale: newLocale }))
    },
    [dispatch]
  )

  return [locale, setLocale]
}

export function useRouterPreference(): [RouterPreference, (routerPreference: RouterPreference) => void] {
  const dispatch = useAppDispatch()

  const routerPreference = useAppSelector((state) => state.user.userRouterPreference)

  const setRouterPreference = useCallback(
    (newRouterPreference: RouterPreference) => {
      dispatch(updateUserRouterPreference({ userRouterPreference: newRouterPreference }))
    },
    [dispatch]
  )

  return [routerPreference, setRouterPreference]
}

/**
 * Return the user's slippage tolerance, from the redux store, and a function to update the slippage tolerance
 */
export function useUserSlippageTolerance(): [
  Percent | SlippageTolerance.Auto,
  (slippageTolerance: Percent | SlippageTolerance.Auto) => void
] {
  const userSlippageToleranceRaw = useAppSelector((state) => {
    return state.user.userSlippageTolerance
  })

  // TODO(WEB-1985): Keep `userSlippageTolerance` as Percent in Redux store and remove this conversion
  const userSlippageTolerance = useMemo(
    () =>
      userSlippageToleranceRaw === SlippageTolerance.Auto
        ? SlippageTolerance.Auto
        : new Percent(userSlippageToleranceRaw, 10_000),
    [userSlippageToleranceRaw]
  )

  const dispatch = useAppDispatch()
  const setUserSlippageTolerance = useCallback(
    (userSlippageTolerance: Percent | SlippageTolerance.Auto) => {
      let value: SlippageTolerance.Auto | number
      try {
        value =
          userSlippageTolerance === SlippageTolerance.Auto
            ? SlippageTolerance.Auto
            : JSBI.toNumber(userSlippageTolerance.multiply(10_000).quotient)
      } catch (error) {
        value = SlippageTolerance.Auto
      }
      dispatch(
        updateUserSlippageTolerance({
          userSlippageTolerance: value,
        })
      )
    },
    [dispatch]
  )

  return [userSlippageTolerance, setUserSlippageTolerance]
}

/**
 *Returns user slippage tolerance, replacing the auto with a default value
 * @param defaultSlippageTolerance the value to replace auto with
 */
export function useUserSlippageToleranceWithDefault(defaultSlippageTolerance: Percent): Percent {
  const [allowedSlippage] = useUserSlippageTolerance()
  return allowedSlippage === SlippageTolerance.Auto ? defaultSlippageTolerance : allowedSlippage
}

export function useUserHideClosedPositions(): [boolean, (newHideClosedPositions: boolean) => void] {
  const dispatch = useAppDispatch()

  const hideClosedPositions = useAppSelector((state) => state.user.userHideClosedPositions)

  const setHideClosedPositions = useCallback(
    (newHideClosedPositions: boolean) => {
      dispatch(updateHideClosedPositions({ userHideClosedPositions: newHideClosedPositions }))
    },
    [dispatch]
  )

  return [hideClosedPositions, setHideClosedPositions]
}

export function useUserTransactionTTL(): [number, (slippage: number) => void] {
  const { chainId } = useWeb3React()
  const dispatch = useAppDispatch()
  const userDeadline = useAppSelector((state) => state.user.userDeadline)
  const onL2 = false
  const deadline = onL2 ? L2_DEADLINE_FROM_NOW : userDeadline

  const setUserDeadline = useCallback(
    (userDeadline: number) => {
      dispatch(updateUserDeadline({ userDeadline }))
    },
    [dispatch]
  )

  return [deadline, setUserDeadline]
}

export function useAddUserToken(): (token: Token) => void {
  const dispatch = useAppDispatch()
  return useCallback(
    (token: Token) => {
      dispatch(addSerializedToken({ serializedToken: serializeToken(token) }))
    },
    [dispatch]
  )
}

function useUserAddedTokensOnChain(chainId: number | undefined | null): Token[] {
  const serializedTokensMap = useAppSelector(({ user: { tokens } }) => tokens)

  return useMemo(() => {
    if (!chainId) return []
    const tokenMap: Token[] = serializedTokensMap?.[chainId]
      ? Object.values(serializedTokensMap[chainId]).map((value) => deserializeToken(value, UserAddedToken))
      : []
    return tokenMap
  }, [serializedTokensMap, chainId])
}

export function useUserAddedTokens(): Token[] {
  return useUserAddedTokensOnChain(useWeb3React().chainId)
}

function serializePair(pair: Pair): SerializedPair {
  return {
    token0: serializeToken(pair.token0),
    token1: serializeToken(pair.token1),
  }
}

export function usePairAdder(): (pair: Pair) => void {
  const dispatch = useAppDispatch()

  return useCallback(
    (pair: Pair) => {
      dispatch(addSerializedPair({ serializedPair: serializePair(pair) }))
    },
    [dispatch]
  )
}

export function useHideAndroidAnnouncementBanner(): [boolean, () => void] {
  const dispatch = useAppDispatch()
  const hideAndroidAnnouncementBanner = useAppSelector((state) => state.user.hideAndroidAnnouncementBanner)

  const toggleHideAndroidAnnouncementBanner = useCallback(() => {
    dispatch(updateHideAndroidAnnouncementBanner({ hideAndroidAnnouncementBanner: true }))
  }, [dispatch])

  return [hideAndroidAnnouncementBanner, toggleHideAndroidAnnouncementBanner]
}

export function useUserDisabledUniswapX(): boolean {
  return useAppSelector((state) => state.user.disabledUniswapX) ?? false
}

export function useUserOptedOutOfUniswapX(): boolean {
  return useAppSelector((state) => state.user.optedOutOfUniswapX) ?? false
}

/**
 * Given two tokens return the liquidity token that represents its liquidity shares
 * @param tokenA one of the two tokens
 * @param tokenB the other token
 */
export function toV2LiquidityToken([tokenA, tokenB]: [Token, Token]): Token {
  if (tokenA.chainId !== tokenB.chainId) throw new Error('Not matching chain IDs')
  if (tokenA.equals(tokenB)) throw new Error('Tokens cannot be equal')
  if (!V2_FACTORY_ADDRESSES[tokenA.chainId]) throw new Error('No V2 factory address on this chain')

  return new Token(
    tokenA.chainId,
    computePairAddress({ factoryAddress: V2_FACTORY_ADDRESSES[tokenA.chainId], tokenA, tokenB }),
    18,
    'ES-LP',
    'ElectroSwap LP'
  )
}

/**
 * Returns all the pairs of tokens that are tracked by the user for the current chain ID.
 */
export function useTrackedTokenPairs(): [Token, Token][] {
  const { chainId } = useWeb3React()
  const tokens = useDefaultActiveTokens(chainId)

  // pinned pairs
  const pinnedPairs = useMemo(() => (chainId ? PINNED_PAIRS[chainId] ?? [] : []), [chainId])

  // pairs for every token against every base
  const generatedPairs: [Token, Token][] = useMemo(
    () =>
      chainId
        ? Object.keys(tokens).flatMap((tokenAddress) => {
            const token = tokens[tokenAddress]
            // for each token on the current chain,
            return (
              // loop though all bases on the current chain
              (BASES_TO_TRACK_LIQUIDITY_FOR[chainId] ?? [])
                // to construct pairs of the given token with each base
                .map((base) => {
                  if (base.address === token.address) {
                    return null
                  } else {
                    return [base, token]
                  }
                })
                .filter((p): p is [Token, Token] => p !== null)
            )
          })
        : [],
    [tokens, chainId]
  )

  // pairs saved by users
  const savedSerializedPairs = useAppSelector(({ user: { pairs } }) => pairs)

  const userPairs: [Token, Token][] = useMemo(() => {
    if (!chainId || !savedSerializedPairs) return []
    const forChain = savedSerializedPairs[chainId]
    if (!forChain) return []

    return Object.keys(forChain).map((pairId) => {
      return [deserializeToken(forChain[pairId].token0), deserializeToken(forChain[pairId].token1)]
    })
  }, [savedSerializedPairs, chainId])

  const combinedList = useMemo(
    () => userPairs.concat(generatedPairs).concat(pinnedPairs),
    [generatedPairs, pinnedPairs, userPairs]
  )

  return useMemo(() => {
    // dedupes pairs of tokens in the combined list
    const keyed = combinedList.reduce<{ [key: string]: [Token, Token] }>((memo, [tokenA, tokenB]) => {
      const sorted = tokenA.sortsBefore(tokenB)
      const key = sorted ? `${tokenA.address}:${tokenB.address}` : `${tokenB.address}:${tokenA.address}`
      if (memo[key]) return memo
      memo[key] = sorted ? [tokenA, tokenB] : [tokenB, tokenA]
      return memo
    }, {})

    return Object.keys(keyed).map((key) => keyed[key])
  }, [combinedList])
}

export function useLockedV2TokenPairs(): { liquidityToken: Token; tokens: [Token, Token]; lockInfos: any[] }[] {
  const { account, chainId } = useWeb3React()
  const v2Locker = useLiquidityLockerV2Contract()
  const v2LockIds = useSingleCallResult(v2Locker, 'getLockIDsByOwner', [account])

  const v2LockInfos = useSingleContractMultipleData(
    v2LockIds?.result?.[0]?.length > 0 ? v2Locker : undefined,
    'getLockById',
    v2LockIds?.result
      ? v2LockIds?.result?.[0]?.map((lockId: number) => {
          return [lockId]
        })
      : [],
    NEVER_RELOAD
  )

  const locksWithLiquidity = v2LockInfos
    ?.filter((callResult) => !callResult.loading && !callResult.result?.[0]?.amount.isZero())
    .map((callResult) => callResult.result?.[0])

  const pairAddresses = locksWithLiquidity?.map((lockInfo) => lockInfo?.pair)
  const tokenZeros = useMultipleContractSingleData(pairAddresses, PAIR_INTERFACE, 'token0', undefined, NEVER_RELOAD)
  const tokenOnes = useMultipleContractSingleData(pairAddresses, PAIR_INTERFACE, 'token1', undefined, NEVER_RELOAD)

  const tokenZeroAddresses = tokenZeros
    ?.filter((callResult) => !callResult.loading)
    .map((callResult) => callResult?.result?.[0])

  const tokenOneAddresses = tokenOnes
    ?.filter((callResult) => !callResult.loading)
    .map((callResult) => callResult?.result?.[0])

  const dedupedAddresses = tokenZeroAddresses.concat(tokenOneAddresses).filter(function (item, pos, self) {
    return self.indexOf(item) == pos
  })

  const defaultTokens = useDefaultActiveTokens(chainId)

  const savedSerializedTokens = useAppSelector(({ user: { tokens } }) => tokens)
  const userTokens: any = useMemo(() => {
    if (!chainId || !savedSerializedTokens) return []
    const forChain = savedSerializedTokens[chainId]

    const result: { [address: string]: Token } = {}

    if (!forChain) return result
    Object.keys(forChain).forEach((tokenAddress: string) => {
      result[tokenAddress] = deserializeToken(forChain[tokenAddress])
    })
    return result
  }, [savedSerializedTokens, chainId])

  const missingAddresses = useMemo(() => {
    if (!dedupedAddresses || !defaultTokens || !userTokens) return []
    return dedupedAddresses.filter((address) => {
      return !defaultTokens[address] && !userTokens[address]
    })
  }, [dedupedAddresses, defaultTokens, userTokens])

  const missingSymbols = useMultipleContractSingleData(
    missingAddresses,
    TOKEN_INTERFACE,
    'symbol',
    undefined,
    NEVER_RELOAD
  )
  const missingNames = useMultipleContractSingleData(missingAddresses, TOKEN_INTERFACE, 'name', undefined, NEVER_RELOAD)
  const missingDecimals = useMultipleContractSingleData(
    missingAddresses,
    TOKEN_INTERFACE,
    'decimals',
    undefined,
    NEVER_RELOAD
  )
  const addUserToken = useAddUserToken()

  const missingTokens: any = useMemo(() => {
    if (
      !addUserToken ||
      !chainId ||
      !missingAddresses ||
      missingSymbols?.[0]?.loading ||
      missingNames?.[0]?.loading ||
      missingDecimals?.[0]?.loading
    )
      return undefined

    const results: any = {}
    missingSymbols.forEach((callResult, pos) => {
      const tokenSymbol = callResult?.result?.[0]
      const tokenName = missingNames?.[pos]?.result?.[0]
      const tokenDecimals = missingDecimals?.[pos]?.result?.[0]
      const token = new Token(chainId, missingAddresses[pos], tokenDecimals, tokenSymbol, tokenName)

      addUserToken(token)
      results[missingAddresses[pos]] = token
    })
    return results
  }, [addUserToken, chainId, missingAddresses, missingSymbols, missingNames, missingDecimals])

  return useMemo(() => {
    if (
      !tokenZeroAddresses ||
      !tokenOneAddresses ||
      !defaultTokens ||
      !userTokens ||
      !missingTokens ||
      !locksWithLiquidity
    )
      return []
    const seen = new Set()
    return tokenZeroAddresses
      .map((token0Address, pos) => {
        const token1Address = tokenOneAddresses[pos]
        const tokenA = defaultTokens[token0Address] || userTokens[token0Address] || missingTokens[token0Address]
        const tokenB = defaultTokens[token1Address] || userTokens[token1Address] || missingTokens[token1Address]
        const liquidityToken = toV2LiquidityToken([tokenA, tokenB])
        const lockInfos = locksWithLiquidity.filter((lockInfo) => lockInfo.pair === liquidityToken.address)

        return {
          liquidityToken,
          tokens: [tokenA, tokenB] as [Token, Token],
          lockInfos,
        }
      })
      .filter((res) => {
        if (!seen.has(res.liquidityToken.address)) {
          seen.add(res.liquidityToken.address)
          return true
        }
        return false
      })
  }, [tokenZeroAddresses, tokenOneAddresses, defaultTokens, userTokens, missingTokens, locksWithLiquidity])
}
