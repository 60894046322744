import { Trans } from '@lingui/macro'
import { BrowserEvent, InterfaceElementName, SharedEventName } from '@uniswap/analytics-events'
import { useWeb3React } from '@web3-react/core'
import { TraceEvent } from 'analytics'
import { ButtonText } from 'components/Button'
import { MouseoverTooltip } from 'components/Tooltip'
import { Chain } from 'graphql/data/__generated__/types-and-hooks'
import { getTokenDetailsURL } from 'graphql/data/util'
import { useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { ClassicTrade, InterfaceTrade } from 'state/routing/types'
import styled from 'styled-components'

const StyledTextButton = styled(ButtonText)`
  color: ${({ theme }) => theme.neutral2};
  gap: 4px;
  font-weight: 485;
  &:focus {
    text-decoration: none;
  }
  &:active {
    text-decoration: none;
  }
`
type Token = {
  address: string
}

export default function SwapTokenDetailsButton({ trade, embedded }: { trade?: InterfaceTrade; embedded?: boolean }) {
  const { account, chainId } = useWeb3React()

  const navigate = useNavigate()
  const navigateToTokenDetails = useCallback(async () => {
    if (trade) {
      const route = (trade as ClassicTrade)?.routes[0]
      const inputAddress = route.input.isNative ? 'ETN' : (route.input as Token).address
      const token = {
        address: (route.output as Token).address,
        chain: chainId === 52014 ? Chain.Electroneum : Chain.ElectroneumTest,
      }

      if (!embedded) {
        navigate(getTokenDetailsURL({ ...token, isInfoExplorePageEnabled: true, inputAddress }))
      } else {
        navigate('/swap?inputCurrency=' + inputAddress + '&outputCurrency=' + token.address)
      }
    }
  }, [navigate, trade, embedded, chainId])

  const isDisabled = useMemo(() => {
    if (trade) {
      return false
    }
    return true
  }, [trade])

  return (
    <MouseoverTooltip
      text={
        <div data-testid="token-details-tooltip">
          <Trans>
            {embedded ? 'Go back to simple swap experience' : 'View token charts, recent transactions, and more!'}
          </Trans>
        </div>
      }
      placement="bottom"
    >
      <TraceEvent
        events={[BrowserEvent.onClick]}
        name={SharedEventName.ELEMENT_CLICKED}
        element={InterfaceElementName.TOKEN_SELECTOR_ROW}
        properties={{ account_connected: !!account }}
      >
        <StyledTextButton onClick={navigateToTokenDetails} disabled={isDisabled} data-testid="token-details-button">
          <Trans>{embedded ? 'Simple' : 'Details'}</Trans>
        </StyledTextButton>
      </TraceEvent>
    </MouseoverTooltip>
  )
}
