import { ChainId } from '@electroswap/sdk-core'
import { getChainInfo } from 'constants/chainInfo'
import { isSupportedChain, SupportedInterfaceChain } from 'constants/chains'
import { CSSProperties, FunctionComponent } from 'react'
import { useTheme } from 'styled-components'
import { useIsDarkMode } from 'theme/components/ThemeToggle'

import { ReactComponent as electroneum } from './ChainSymbols/electroneum.svg'

type SVG = FunctionComponent<React.SVGProps<SVGSVGElement>>
type ChainUI = { Symbol: SVG; bgColor: string; textColor: string }

export function getChainUI(chainId: SupportedInterfaceChain, darkMode: boolean): ChainUI
export function getChainUI(chainId: ChainId, darkMode: boolean): ChainUI | undefined {
  switch (chainId) {
    case ChainId.ELECTRONEUM:
    case ChainId.ELECTRONEUM_TEST:
      return {
        Symbol: electroneum,
        bgColor: '#6B8AFF00',
        textColor: '#6B8AFF',
      }

    default:
      return undefined
  }
}

// eslint-disable-next-line import/no-unused-modules
export const getDefaultBorderRadius = (size: number) => size / 2 - 4

type ChainLogoProps = {
  chainId: ChainId
  className?: string
  size?: number
  borderRadius?: number
  style?: CSSProperties
  testId?: string
}
export function ChainLogo({
  chainId,
  className,
  style,
  size = 16,
  borderRadius = getDefaultBorderRadius(size),
  testId,
}: ChainLogoProps) {
  const darkMode = useIsDarkMode()
  const { surface2 } = useTheme()

  if (!isSupportedChain(chainId)) return null
  const { label } = getChainInfo(chainId)

  const { Symbol, bgColor } = getChainUI(chainId, darkMode)
  return (
    <svg width={size} height={size} className={className} style={style} aria-labelledby="titleID" data-testid={testId}>
      <title id="titleID">{`${label} logo`}</title>
      <rect rx={borderRadius} fill={surface2} width={size} height={size} />
      <rect rx={borderRadius} fill={bgColor} width={size} height={size} />
      <Symbol width={size} height={size} />
    </svg>
  )
}
